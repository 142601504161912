<script setup>
import { useQuitWithoutSaving } from '~/composables/useQuitWithoutSaving'
import useBeamer from '@/composables/useBeamer';

const { currentRoute, isReady } = useRouter()
const { open } = useQuitWithoutSaving()
const { isOnline } = useNetwork()
const { t } = useI18n()

const currentVersion = ref(null);
const isOffline = ref(false)
const isOldVersion = ref(false)

let intervalId;

watch(isOnline, (value) => {
    isOffline.value = !value
})

const refreshNow = () => {
    isOldVersion.value = false
    window.location.reload()
}

const refreshLater = () => {
    isOldVersion.value = false
    clearInterval(intervalId);
}

const checkVersion = async () => {
    try {
        const response = await fetch('/version.json?t=' + Date.now());
        const data = await response.json();
        if (data.version !== currentVersion.value) {
            if(!currentVersion.value) {
                currentVersion.value = data.version
            } else {
                isOldVersion.value = true;
            }
        }
    } catch (error) {
        console.error('Error fetching version:', error);
    }
};



onMounted(() => {
    intervalId = setInterval(checkVersion, 10000);
});

// Cleanup on component unmount
onUnmounted(() => {
    clearInterval(intervalId);
});



useBeamer('zjomVWQb15655');

const isPrevious = useLocalStorage('isPrevious', false);

watch(isPrevious, (currentVal, previousVal) => {
    if (currentVal === false && previousVal === true) {
        window.location.href = 'https://app.posveloce.com/dashboard?switchVersion=true'
    }
});


const locale = useStorage('locale', 'en');

watch(locale, (newLocale) => {
    document.documentElement.setAttribute('lang', newLocale);
}, { immediate: true });
</script>

<template>
    <div :id="(currentRoute.name || '').toLowerCase()" class="h-full">
        <Suspense>
            <RouterView></RouterView>
        </Suspense>
    </div>
    <Toast position="bottom-center"></Toast>

    <QuitWithoutSaving v-model="open"></QuitWithoutSaving>
    <Dialog
        class="connection-lost-dialog"
        :closable="true"
        :header="t('Connection Lost')"
        style="width: 100%; max-width: 400px"
        v-model:visible="isOffline"
        modal
    >
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="mb-4" style="width: 200px; height: 139px; position: relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="139" viewBox="0 0 200 139">
                    <path id="IMG_Glow" d="M169.5,69.5A69.5,69.5,0,1,1,100,0a69.5,69.5,0,0,1,69.5,69.5M18,69.75A3.75,3.75,0,0,0,14.25,66H3.75a3.75,3.75,0,1,0,0,7.5h10.5A3.75,3.75,0,0,0,18,69.75M196.25,66h-10.5a3.75,3.75,0,0,0,0,7.5h10.5a3.75,3.75,0,0,0,0-7.5M23.557,109.369l-8.77,5.063a3.8,3.8,0,1,0,3.8,6.578l8.77-5.063a3.8,3.8,0,1,0-3.8-6.577m152.886-79.5,8.77-5.063a3.8,3.8,0,0,0-3.8-6.577l-8.77,5.063a3.8,3.8,0,0,0,3.8,6.578M27.354,23.293l-8.77-5.063a3.8,3.8,0,1,0-3.8,6.577l8.77,5.063a3.8,3.8,0,1,0,3.8-6.577m157.858,91.139-8.77-5.063a3.8,3.8,0,0,0-3.8,6.577l8.77,5.063a3.8,3.8,0,0,0,3.8-6.578" fill="var(--gray-200)"/>
                </svg>

                <svg style="position: absolute; top: 50%; left: 50%; margin: -33px 0 0 -40px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80" height="67.594" viewBox="0 0 80 67.594">
                    <defs>
                        <linearGradient id="linear-gradient" x2="1" y2="1" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#006cc5"/>
                            <stop offset="1" stop-color="var(--primary-color)"/>
                        </linearGradient>
                    </defs>
                    <path id="IMG_No-Network" d="M45.622,61.958a5.629,5.629,0,1,1-5.629-5.629,5.629,5.629,0,0,1,5.629,5.629M32.375,25.035A36.357,36.357,0,0,0,14.1,34.962,4.48,4.48,0,0,0,20.441,41.3a27.489,27.489,0,0,1,12.271-7.133ZM31.707,6.759a54.493,54.493,0,0,0-30.4,15.4,4.48,4.48,0,1,0,6.336,6.336A45.418,45.418,0,0,1,32.043,15.778Zm1.316,36.034a22.034,22.034,0,0,0-8.683,5.364,4.48,4.48,0,0,0,6.336,6.336,13.2,13.2,0,0,1,3.369-2.447,6.726,6.726,0,0,1-.787-2.928ZM78.676,22.155a54.493,54.493,0,0,0-30.391-15.4l-.329,9.015A45.418,45.418,0,0,1,72.351,28.488a4.48,4.48,0,1,0,6.336-6.336M47.618,25.035l-.334,9.13a27.489,27.489,0,0,1,12.271,7.127,4.48,4.48,0,1,0,6.336-6.336,36.357,36.357,0,0,0-18.27-9.927m-.648,17.758-.231,6.324a6.726,6.726,0,0,1-.787,2.928,13.2,13.2,0,0,1,3.362,2.454,4.48,4.48,0,0,0,6.336-6.336,22.034,22.034,0,0,0-8.683-5.367M36.242,49a3.76,3.76,0,0,0,7.514,0L45.34,5.539a5.348,5.348,0,1,0-10.688,0Z" transform="translate(0.001 0.007)" fill="url(#linear-gradient)"/>
                </svg>

            </div>
            <p class="text-lg text-center">
                {{
                    t(
                        "The internet connection has been lost and that some functionalities may not be available until the connection is restored."
                    )
                }}
            </p>
            <Button @click="isOffline = false" class="mb-2">{{ t('Continue') }}</Button>
        </div>
    </Dialog>

    <Dialog
        :closable="false"
        :header="t('New Version Available')"
        class="new-version-dialog"
        style="width: 100%;"
        v-model:visible="isOldVersion"
        position="bottom"
        :showHeader="false"
        :modal="false"
    >
        <div class="container justify-between content-between flex flex-column xl:flex-row align-items-center justify-content-between">
            <div class="flex flex-column xl:flex-row align-items-center justify-content-center text-sm lg:text-lg text-center m-0">
                <div class="icon text-center flex align-items-center justify-content-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.505" height="16" viewBox="0 0 12.505 16">
                        <defs>
                            <linearGradient id="linear-gradient" x2="1" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#006cc5"/>
                                <stop offset="1" stop-color="var(--primary-color)"/>
                            </linearGradient>
                        </defs>
                        <path id="IMG_Immportant-News" d="M180.626-846.34a.6.6,0,0,1-.445-.18.605.605,0,0,1-.18-.446.6.6,0,0,1,.18-.445.605.605,0,0,1,.445-.18h.882v-6.221a4.614,4.614,0,0,1,1.038-2.974,4.6,4.6,0,0,1,2.665-1.653v-.519a1,1,0,0,1,.3-.738,1,1,0,0,1,.737-.3,1.007,1.007,0,0,1,.738.3,1,1,0,0,1,.3.738v.519a4.6,4.6,0,0,1,2.665,1.653A4.614,4.614,0,0,1,191-853.811v6.221h.882a.6.6,0,0,1,.445.18.606.606,0,0,1,.18.446.6.6,0,0,1-.18.445.605.605,0,0,1-.445.18ZM186.254-852.448Zm0,8.449a1.448,1.448,0,0,1-1.064-.443,1.453,1.453,0,0,1-.442-1.064h3.014a1.45,1.45,0,0,1-.443,1.065A1.455,1.455,0,0,1,186.252-844Zm-3.494-3.591h6.99v-6.221a3.367,3.367,0,0,0-1.024-2.471,3.367,3.367,0,0,0-2.471-1.024,3.367,3.367,0,0,0-2.471,1.024,3.367,3.367,0,0,0-1.024,2.471Zm3.5-3.736a.6.6,0,0,0,.445-.18.605.605,0,0,0,.18-.445v-2.838a.6.6,0,0,0-.18-.445.605.605,0,0,0-.446-.18.6.6,0,0,0-.445.18.605.605,0,0,0-.18.445v2.838a.6.6,0,0,0,.18.445A.605.605,0,0,0,186.254-851.326Zm0,2.421a.652.652,0,0,0,.48-.194.652.652,0,0,0,.194-.48.652.652,0,0,0-.194-.48.652.652,0,0,0-.48-.194.652.652,0,0,0-.48.194.652.652,0,0,0-.194.48.652.652,0,0,0,.194.48A.652.652,0,0,0,186.254-848.9Z" transform="translate(-180.001 859.999)" fill="url(#linear-gradient)"/>
                    </svg>
                </div>
                <div class="flex flex-column align-items-center xl:align-items-start">
                <h6 class="mt-2 xl:mt-0text-center xl:text-left">{{ t('New Veloce version available!') }}</h6>
                <h5 class="text-center xl:text-left mb-4 xl:mb-0">
                {{
                    t(
                        "We noticed you are currently using a previous version of Veloce Analytics. Refresh your page to access new features and improvements."
                    )
                }}
                </h5>
                </div>
            </div>
            <div class="flex flex-row flex-grow-0">
                <Button @click="refreshNow" class="xl:ml-4">{{ t('Refresh') }}</Button>
                <Button @click="refreshLater" class="md:h-auto md:mt-0 p-button-text">{{ t('Continue') }}</Button>
            </div>
        </div>
    </Dialog>
</template>

<style lang="scss">
.beamer_icon {
    background-color: black !important;
}
#beamerSelector {
    display: none;
}
#newVersion {
    z-index: 100000;
    flex: 0 0 240px;
    display: flex;
    width: 240px !important;
    border-radius: 16px !important;
    box-shadow: 0px 4px 12px #00000029 !important;
    h6 {
        font: normal normal bold 14px/19px Roboto;
        color: var(--gray-800)
    }
    p {
        font: normal normal normal 12px/16px Roboto !important;
        color: var(--gray-700);
    }
    .p-overlaypanel-content {
        padding: 4px !important;
    }
}
.p-button:focus {
    box-shadow: none !important;
}
.connection-lost-dialog {
    .p-dialog-title {
        font: normal normal bold 14px/19px Roboto !important;
    }

    .p-dialog-content {
        background-color: transparent !important;
    }
}

.new-version-dialog {
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 32px 0;
    background: var(--surface-a) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 48px #00000029;

    .container {
        width: 100% !important;
        max-width: 1140px;
        margin: 0 auto;
    }

    h6 {
        text-align: left;
        font: normal normal bold 12px/16px Roboto;
        letter-spacing: 0px;
        color: var(--primary-color);
        margin: 0;
    }

    h5 {
        text-align: left;
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: -0.02px;
        margin: 0;
    }
    .icon {
        width: 32px; height: 32px;
        background: var(--gray-200) 0% 0% no-repeat padding-box;
        border-radius: 50%;
        margin-right: 16px;
    }
    .p-dialog-title {
    font: normal normal bold 14px/19px Roboto !important;
}

    .p-dialog-content {
        background-color: transparent !important;
        padding: 0!important;
    }
}


.cky-btn-revisit-wrapper {
    visibility: hidden !important;
}

div[data-cky-tag='detail-powered-by'] {
    display: none !important;
}

.bread {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    color: var(--gray-800);
}

.p-dialog-mask {
    z-index: 1000;
}

.p-multiselect-panel {
    z-index: 1000;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--gray-700);
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: var(--gray-700);

        &.router-link-exact-active {
            color: var(--gray-800);
        }
    }
}


</style>
