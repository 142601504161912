export default function useBeamer(productId) {
    const user = useLocalStorage('user', {});
    const loadBeamer = () => {
        window.beamer_config = {
            product_id: productId,
            selector: '.announcements__action'
        };
        (function() {
            const beamer = document.createElement('script');
            beamer.type = 'text/javascript';
            beamer.async = true;
            beamer.src = 'https://app.getbeamer.com/js/beamer-embed.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(beamer, s);
        })();
    };

    watch(user, user => {
        window.beamer_config = {
            ...window.beamer_config,
            selector: '.announcements__action',
            user_firstname: user.firstName,
            user_lastname: user.lastName,
            user_email: user.email
        };
    }, {immediate: true})

    onMounted(() => {
        loadBeamer();
    });
}
