import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import router from '~/router'
import { getAllLocations } from '~/services/locations.service'
import { getAllGroups } from '~/services/groups.service'
import { cloneDeep, uniq, uniqBy } from 'lodash'
import { createEventHook } from '@vueuse/core'
import { deleteUserPresets, getUsersPresets, putUserPresets } from "~/services/users.service";

export const useMultiSelectorStore = defineStore('multi-selector', () => {
    const groups = ref([])
    const locations = ref([])
    const isLoadingLocations = ref(true)
    const enableGroup = ref(false)
    const enableLocation = ref(false)
    const visibleBottom = ref(false)
    const isLoadingGroups = ref(true)
    const selectedCurrency = ref(null)
    const selectedCurrencySymbol = ref(null)
    const selectedGroupIds = ref([])
    const isShowSelected = ref(true)
    const locationIDs = ref([])
    const presets = ref([])
    const currentSelectedLocations = ref([])
    const currentSelectedGroups = ref([])
    const selectedPreset = ref(null);

    const reset = (force = false) => {
        //selectedCurrency.value = ref(null);
        if(force) {
            locations.value = [];
        }
        selectedGroupIds.value = ref([])
        visibleBottom.value = false
        selectedCurrency.value = locations.value[0]?.currency || null
        selectedCurrencySymbol.value = locations.value[0]?.currencySymbol || null
    }

    const onCreate = createEventHook()

    const createHandler = (e) => {
        onCreate.trigger(e)
    }

    const getLocationById = (id) => {
        return locations.value.find((l) => l.id === id) || {}
    }

    const getLocationNameById = (id, defaultValue) => {
        return locations.value.find((l) => l.id === id)?.name || defaultValue
    }

    const getLocations = async (form) => {

        function deduplicateNames(items) {
            const nameCount = {};
            const nameIndex = {};

            // Première passe : Compter les occurrences de chaque nom
            items.forEach(item => {
                if (!nameCount[item.name]) {
                    nameCount[item.name] = 0;
                }
                nameCount[item.name] += 1;
            });

            // Deuxième passe : Ajouter des suffixes uniquement aux noms qui apparaissent plus d'une fois
            return items.map(item => {
                const baseName = item.name;
                if (nameCount[baseName] > 1) {
                    if (!nameIndex[baseName]) {
                        nameIndex[baseName] = 0;
                    }
                    nameIndex[baseName] += 1;
                    const newName = `${baseName} #${nameIndex[baseName]}`;
                    return { ...item, name: newName };
                }
                return item;
            });
        }

        return new Promise((resolve, reject) => {
            isLoadingLocations.value = true
            const { onFetchError, onFetchResponse, data } = getAllLocations()

            onFetchResponse(() => {

                locations.value = deduplicateNames(data.value || []).map((g) => ({
                    id: g.id,
                    countryName: g.countryName,
                    countryCode: g.countryCode,
                    name: (g.reportingDisplayName !== '' ? g.reportingDisplayName
                         : g.name) || g.licenceNumber,
                    groups: g.groups || [],
                    currency: (g.detailedCurrency && g.detailedCurrency.currencyCode) || null,
                    currencySymbol: (g.detailedCurrency && g.detailedCurrency.currencySymbol) || null,
                }))

                locations.value.forEach((g) =>{

                })

                if (locations.value.length === 1) {
                    selectedCurrency.value = locations.value[0]?.currency || null
                    selectedCurrencySymbol.value = locations.value[0]?.currencySymbol || null
                }

                enableLocation.value = locations.value.length > 1
                isLoadingLocations.value = false
                resolve(locations.value)
            })

            onFetchError((error) => {
                isLoadingLocations.value = false
                reject(error)
            })

            return true
        })
    }

    const getGroups = async (locations) => {
        return new Promise((resolve, reject) => {
            isLoadingGroups.value = true
            const { onFetchError, onFetchResponse, data } = getAllGroups()

            onFetchResponse(() => {
                groups.value = (data.value || []).map((g) => ({
                    id: g.id,
                    name: g.name,
                    currency: uniq(
                        locations
                            .filter((l) => l.groups.filter((lg) => lg.id === g.id).length > 0)
                            .map((l) => l.currency)
                    ),
                }))
                enableGroup.value = groups.value.length > 1
                isLoadingGroups.value = false
                resolve(enableGroup.value)
            })

            onFetchError((error) => {
                isLoadingGroups.value = false
                reject(error)
            })

            return true
        })
    }

    const updatePresets = async () => {
        const { data } = await  getUsersPresets();
        presets.value = data.value;
    }
    const innitialize = async ({ query }) => {
        selectedCurrency.value = query.currency || locations.value[0]?.currency || null
        selectedCurrencySymbol.value = locations.value[0]?.currencySymbol || null
        selectedGroupIds.value = Array.from(
            typeof query.groupIDs === 'string' ? [query.groupIDs] : query.groupIDs || []
        )
        locationIDs.value = Array.from(
            typeof query.locationIDs === 'string' ? [query.locationIDs] : query.locationIDs || []
        )

        updatePresets();
        if (!locations.value.length) {
            const locations = await getLocations()

            if (locations?.length === 1) {
                locationIDs.value = locations[0]?.id ? [locations[0]?.id] : []
            }

            if (!groups.value.length) {
                await getGroups(locations)
            }
        }

        return locations.value
    }

    const isReady = computed(() => !isLoadingLocations.value && !isLoadingGroups.value)
    const loading = computed(() => isLoadingLocations.value || !isLoadingGroups.value)

    const enableCurrency = computed(() => currencies.value.length > 1)

    const selectedGroupLocations = computed(() => {
        return locations.value.filter((l) => {
            if (selectedGroupIds.value && selectedGroupIds.value.length) {
                return l.groups.filter((g) => selectedGroupIds.value.includes(g.id)).length
            }

            return true
        })
    })

    const selectedLocations = computed(() => {
        return locations.value.filter((l) => {
            if (selectedCurrency.value && selectedGroupIds.value && selectedGroupIds.value.length) {
                return (
                    l.currency === selectedCurrency.value &&
                    l.groups.filter((g) => selectedGroupIds.value.includes(g.id) || groups.value.length === 1).length
                )
            }

            if (selectedCurrency.value) {
                return l.currency === selectedCurrency.value
            }

            if (selectedGroupIds.value && selectedGroupIds.value.length && groups.value.length > 1) {
                return l.groups.filter((g) => selectedGroupIds.value.includes(g.id)).length
            }

            return true
        })
    })

    const currencies = computed(() =>
        uniq([...selectedGroupLocations.value.map((l) => l.currency)]).map((c) => ({ id: c, name: c }))
    )

    const changeCurrency = (currency) => {
        selectedCurrency.value = currency
    }

    const changeLocations = (locations) => {
        if(locations[0]?.currency) {
            selectedCurrency.value = locations[0].currency
            selectedCurrencySymbol.value = locations[0].currencySymbol || null
        }
    }

    const changeGroups = (groups) => {
        selectedGroupIds.value = groups || []

        nextTick(() => {
            if (currencies.value.length) {
                changeCurrency(currencies.value[0].id)
            }
        })
    }

    const activeLocations = computed(() => {
        return !locationIDs.value.length
            ? selectedGroupLocations.value
            : locations.value.filter((l) => {
                  return locationIDs.value.includes(l.id)
              })
    })

    const selectedGroups = computed(() => {
        return groups.value.filter((g) => {
            //if (selectedCurrency.value) {
            //    return g.currency.includes(selectedCurrency.value);
            //}

            return true
        })
    })

    const locationsCountryCode = computed(() => {
        return uniqBy(
            locations.value.map((l) => ({
                name: l.countryName,
                value: l.countryCode,
            })),
            'value'
        ).sort((a, b) => a.value.localeCompare(b.value))
    })

    const removeLocation = (locationId) => {
        const currentRouteQuery = cloneDeep(router.currentRoute.value.query)

        if (!Array.isArray(currentRouteQuery.locationIDs)) {
            delete currentRouteQuery.locationIDs
        } else {
            currentRouteQuery.locationIDs = currentRouteQuery.locationIDs.filter((id) => id !== locationId)

        }

        if ((currentRouteQuery.locationIDs || []).length === 0) {
            delete currentRouteQuery.from
            delete currentRouteQuery.to
        }

        router.push({ ...router.currentRoute.value, query: currentRouteQuery })
    }

    const selectPreset = (preset) => {

    }
    const savePreset = async (name, id = null) => {
        const payload = {
            id,
            name,
            locationIds: currentSelectedLocations.value.map(l => l.id),
            groupIds: currentSelectedGroups.value.map(l => l.id),
            currency: selectedCurrency.value,
        }

        const test = await putUserPresets(payload)
        selectedPreset.value = test.data.value;
        await updatePresets();
    }
    const updatePreset = async (item) => {
        await putUserPresets({
            ...item,
            locationIds: currentSelectedLocations.value.map(l => l.id),
            groupIds: currentSelectedGroups.value.map(l => l.id),
        })
        await updatePresets();
    }
    const overwritePreset = async (item) => {
        await putUserPresets({
            ...item,
            locationIds: currentSelectedLocations.value.map(l => l.id),
            groupIds: currentSelectedGroups.value.map(l => l.id),
        })
        await updatePresets();
    }
    const deletePreset = async (id = null) => {
        await deleteUserPresets(id)
        await updatePresets();
    }

    return {
        changeCurrency,
        changeGroups,
        getGroups,
        getLocations,
        innitialize,
        removeLocation,
        selectedGroups,
        enableCurrency,
        enableLocation,
        enableGroup,
        currencies,
        groups,
        locations,
        loading,
        isReady,
        isLoadingLocations,
        selectedCurrency,
        selectedCurrencySymbol,
        isLoadingGroups,
        selectedLocations,
        locationsCountryCode,
        visibleBottom,
        onCreate: onCreate.on,
        selectedGroupIds,
        activeLocations,
        isShowSelected,
        createHandler,
        changeLocations,
        getLocationById,
        reset,
        getLocationNameById,
        presets,
        savePreset,
        deletePreset,
        updatePreset,
        overwritePreset,
        selectPreset,
        currentSelectedLocations,
        currentSelectedGroups,
        selectedPreset
    }
})

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useMultiSelectorStore, import.meta.hot))
